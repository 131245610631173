import React from 'react';
import ReactDOM from 'react-dom';
import App, {route, group} from 'library/App';

function Main(){

    route('/@public/Home');
    route('/admin/login.html@admin/views/Login');

    group('admin', () => {

        route('/admin/home.html@admin/views/Home');
        route('/admin/sales.html@admin/views/Sales');
        route('/admin/profits.html@admin/views/Profits');

        route('/admin/services.html@admin/views/ServicesList', 'services@getAll', [

            route('/admin/services/add.html@admin/views/ServicesAdd'),
            route('/admin/services/:services_id/update.html@admin/views/ServicesUpdate'),
            route('/admin/services/:services_id/expenses.html@admin/views/ServicesExpenses', 'expenses@fetchByService', [

                route('/admin/services/:services_id/expenses/add.html@admin/views/ServicesExpensesAdd')
            ])
        ]);

        route('/admin/users/list.html@admin/views/UsersList', 'users@getAll', [

                route('/admin/users/:id/update.html@admin/views/UsersUpdate', 'users@getSingle'),
                route('/admin/users/add.html@admin/views/UsersAdd')
            ]
        );
    });

    return <App />
}

ReactDOM.render(<Main />, document.getElementById('root'));
